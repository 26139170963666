import {createGlobalStyle} from 'styled-components'

import breakpoints from './constants/breakpoints'
import Colours from './constants/colours'

const {PUBLIC_URL} = process.env

const backgroundImage = PUBLIC_URL + '/images/oceanTile.png'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Montserrat', sans-serif;
  }

  html {
    // background-color: ${Colours.background};
    // background-size: 200px 200px;
    // background-image: url(${backgroundImage});
    font-size: 12px;
    overflow: hidden;

    @media (min-width: ${breakpoints.fromSmall}px) {
      font-size: 14px;
    }

    @media (min-width: ${breakpoints.fromMedium}px) {
      font-size: 16px;
    }
  }

  body {
    background: none;
    margin: 0px;
    padding: 0px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
  }

  h1, h2, h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  }
`

export default GlobalStyle
